import { render, staticRenderFns } from "./OSA.vue?vue&type=template&id=4af6c569&scoped=true&"
import script from "./OSA.vue?vue&type=script&lang=js&"
export * from "./OSA.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4af6c569",
  null
  
)

export default component.exports